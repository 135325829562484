
.flex {
    display: flex;
}
.justify-center {
    justify-content: center;
}
.flex-wrap {
    flex-wrap: wrap;
}
@keyframes CircleContainer {
    0%, 25% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-270deg);
    }
    100% {
        transform: rotate(-270deg);
    }
}
@keyframes Circle1 {
    0%, 100% {
        transform: none;
    }
    25% {
        transform: translate(-32px, -32px);
    }
    75% {
        transform: translate(-32px, -32px);
    }
}
@keyframes Circle2 {
    0%, 100% {
        transform: none;
    }
    25% {
        transform: translate(32px, -32px);
    }
    75% {
        transform: translate(32px, -32px);
    }
}
@keyframes Circle3 {
    0%, 100% {
        transform: none;
    }
    25% {
        transform: translate(-32px, 32px);
    }
    75% {
        transform: translate(-32px, 32px);
    }
}
@keyframes Circle4 {
    0%, 100% {
        transform: none;
    }
    25% {
        transform: translate(32px, 32px);
    }
    75% {
        transform: translate(32px, 32px);
    }
}
.CalculationLoading {
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    .containers {
        position: absolute;
        width: 80px;
        height: 80px;
        top: 40%;
        animation: CircleContainer 3s ease 0s infinite normal none;
        .circle {
            position: absolute;
            top: 20px;
            left: 20px;
            height: 20px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #3da5d9;
        }
        .circle:nth-child(1) {
            animation: Circle1 3s ease 0s infinite normal none;
        }
        .circle:nth-child(2) {
            animation: Circle2 3s ease 0s infinite normal none;
        }
        .circle:nth-child(3) {
            animation: Circle3 3s ease 0s infinite normal none;
        }
        .circle:nth-child(4) {
            animation: Circle4 3s ease 0s infinite normal none;
        }
    }
    .hint {
        position: absolute;
        left: 50%;
        top: 60%;
        transform: translate(-50%, 0%);
    }
}
